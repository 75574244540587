/* eslint-disable no-return-assign */
/* eslint-disable jsx-a11y/no-static-element-interactions */
/* eslint-disable react/jsx-props-no-spreading */
import React, { useEffect, useState } from 'react';
import _ from 'lodash';
import { navigate } from 'gatsby';
import styled from 'styled-components';
import { useSelector, useDispatch } from 'react-redux';
import {
  H2, Button, H3,
} from '@allthingswww/client-act-shared';

import Layout from '../components/Layout';
import { getEventByID } from '../redux/event';
import Range from '../components/Range';
import { getMyInfo } from '../redux/member';
import { updatedCheckoutDataSuccess, getEventCheckoutData } from '../redux/eventCheckout';

const MainContainer = styled.div`
`;

const FeeContainer = styled.div`
display: flex;
margin: 15px 0;  
align-items: center;
justify-content: space-between;
margin-bottom: 5rem;
`;
const MemberFee = styled.div`
`;
const Flex = styled.div`

`;

const Row = styled.div`
  display: flex;
  flex-direction: row wrap;
  justify-content: space-between;
`;

const Currency = new Intl.NumberFormat('en-us', { style: 'currency', currency: 'USD' });

const RegisterEventItems = (props) => {
  let entranceFee = null;
  const myInfo = useSelector(getMyInfo);
  const eventCheckoutData = useSelector(getEventCheckoutData);
  const dispatch = useDispatch();
  // eslint-disable-next-line react/destructuring-assignment
  const { attendees } = props.location.state ? props.location.state : { attendees: [] };
  // eslint-disable-next-line react/destructuring-assignment
  const eventDetails = useSelector(getEventByID(props['*']));
  const eventItems = !_.isEmpty(eventDetails) ? JSON.parse(eventDetails[0].eventItems) : [];
  const formatForRange = () => attendees.map((attendee) => {
    const obj = {};
    if (_.isEmpty(eventItems)) {
      return <p>No event items</p>;
    }
    eventItems.map((i) => obj[i.name] = { values: [i.minQty] });
    return ({
      ...attendee,
      eventItems: obj,
    });
  });

  const [quantities, setQuantities] = useState(() => {
    const res = formatForRange();
    return _.isEmpty(eventCheckoutData.amountToBePaidDetails)
      ? res : _.cloneDeep(eventCheckoutData.amountToBePaidDetails);
  });

  if (_.isEmpty(eventDetails)) {
    return (
      <Layout
        title="No event details"
        subtitle=""
      >
        <div>No details about this event</div>
      </Layout>

    );
  }
  const {
    name = '',
    memberFee = 0,
    nonMemberFee = 0,
  } = eventDetails[0];

  const checkIfUserIsAMember = () => {
    if (_.isEmpty(myInfo)) {
      entranceFee = nonMemberFee;
      return entranceFee;
    }
    if (!_.get(myInfo, 'role.isMember')) {
      entranceFee = nonMemberFee;
      return entranceFee;
    }
    if (_.get(myInfo, 'role.isMember')) {
      entranceFee = memberFee;
      return entranceFee;
    }
    return entranceFee;
  };

  const handleGetToCheckout = (e) => {
    e.preventDefault();
    checkIfUserIsAMember();
    const amountToBePaidDetails = { };

    _.forOwn(eventItems, (value) => {
      let totalPerEventItem = 0;
      if (value.maxQty <= value.minQty) {
        totalPerEventItem += value.unitPrice * value.minQty;
      } else {
      // eslint-disable-next-line no-plusplus
        for (let i = 0; i < quantities.length; i++) {
          totalPerEventItem += quantities[i].eventItems[value.name].values[0] * value.unitPrice;
        }
      }
      amountToBePaidDetails[value.name] = totalPerEventItem;
    });

    dispatch(updatedCheckoutDataSuccess({
      amountToBePaidDetails: quantities,
      allItemsToBePaid: { ...amountToBePaidDetails, 'Entrance Fee': entranceFee * attendees.length },
    }));
    navigate(
      `/checkout/${props['*']}`,
      {
        state: { amountToBePaidDetails, attendees },
      },
    );
  };

  return (
    <Layout
      title={name}
      subtitle=""
      condensed
    >
      <MainContainer>
        {attendees.map(({ fullName }, index) => (
          <Flex key={fullName}>
            <H3>
              Event items for
              {' '}
              {fullName}
            </H3>
            {eventItems.map(({
              minQty, maxQty, name: itemName, unitPrice,
            }) => (
              <FeeContainer key={itemName}>
                {
                  (maxQty <= minQty)
                    ? (
                      <output id="output">
                        {minQty}
                      </output>
                    )
                    : (
                      <>
                        <Range
                          allValues={quantities}
                          index={index}
                          name={itemName}
                          min={minQty}
                          max={maxQty}
                          onChange={setQuantities}
                          values={quantities[index].eventItems[itemName].values}
                        />
                        <output id="output">
                          {quantities[index].eventItems[itemName].values}
                        </output>
                      </>
                    )
                }

                <div>
                  {itemName}
                </div>
                <MemberFee>
                  {Currency.format(unitPrice / 100)}
                  / per person
                </MemberFee>
              </FeeContainer>
            ))}
          </Flex>
        ))}

      </MainContainer>
      <Row>
        <Button.Button variant href="" onClick={() => navigate(-1)}>Back</Button.Button>
        <Button.Button variant href="" onClick={handleGetToCheckout}>Next</Button.Button>
      </Row>
    </Layout>
  );
};
export default RegisterEventItems;
