/* eslint-disable no-plusplus */
/* eslint-disable no-return-assign */
/* eslint-disable no-param-reassign */
/* eslint-disable react/jsx-props-no-spreading */
import React from 'react';
import { Range, getTrackBackground } from 'react-range';

const RangeComponent = ({
  step = 1, min = 0, max = 0, values, onChange, name, allValues, index,
}) => {
  function updateState(v) {
    // eslint-disable-next-line no-plusplus
    const copyValues = [...allValues];
    for (let i = 0; i < copyValues.length; i++) {
      if (i === index) {
        copyValues[i].eventItems[name] = { values: v };
      }
    }
    return copyValues;
  }
  return (
    <Range
      values={values}
      step={step}
      min={min}
      max={max}
      onChange={(v) => onChange(updateState(v))}
      renderTrack={({ props: renderTrackProps, children }) => (
      // eslint-disable-next-line jsx-a11y/no-static-element-interactions
        <div
          onMouseDown={renderTrackProps.onMouseDown}
          onTouchStart={renderTrackProps.onTouchStart}
          style={{
            ...renderTrackProps.style,
            height: '36px',
            display: 'flex',
            width: '50%',
          }}
        >
          <div
            ref={renderTrackProps.ref}
            style={{
              height: '5px',
              width: '100%',
              borderRadius: '4px',
              background: getTrackBackground({
                values,
                colors: ['#0F0F3A', '#ccc'],
                min,
                max,
              }),
              alignSelf: 'center',
            }}
          >
            {children}
          </div>
        </div>
      )}
      renderThumb={({ props: renderThumbProps, isDragged }) => (
        <div
          {...renderThumbProps}
          style={{
            ...renderThumbProps.style,
            height: '42px',
            width: '42px',
            borderRadius: '4px',
            backgroundColor: '#FFF',
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
            boxShadow: '0px 2px 6px #AAA',
          }}
        >
          <div
            style={{
              height: '16px',
              width: '5px',
              backgroundColor: isDragged ? '#0F0F3A' : '#CCC',
            }}
          />
        </div>
      )}
    />
  );
};

export default RangeComponent;
